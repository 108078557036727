import React from 'react';
import styles from './Notification.module.css';

const Notification = ({ notification, isVisible }) => {
  if (!notification) return null;

  return (
    <div className={`${styles.notification} ${isVisible ? styles.show : styles.hide}`}>
      <div className={`${styles.content} ${styles[notification.type]}`}>
        {notification.message}
      </div>
    </div>
  );
};

export default Notification;