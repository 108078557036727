import { loaderGif } from '#/data/data'
import styles from './ImageLoader.module.css'

export default function ImageLoader({ isLoadedEnd, isLoaded }) {
  if (isLoaded) return
  return (
    <div className={`${styles.loader} ${isLoadedEnd ? styles.hidden : ''}`}>
      <img src={loaderGif} width={80} height={80} alt="loader" />
    </div>
  )
}
