import React, { useCallback, useState, useEffect } from 'react'
import Modal from '#/components/Modal/Modal'
import TextInput from '#/components/forms/TextInput/TextInput'
import coinImage from '#/assets/coin.png'
import TabsNavBar from '#/components/navigation/TabsNavBar'
import { useUser } from '#/context/userContext'
import styles from './Withdraw.module.css'
import { formatNumber, shortWalletAddress } from '#/services/helpers'
import PrimaryButton from '#/components/buttons/PrimaryButton/PrimaryButton'
import { useTonConnect } from '#/hooks/useTonConnect'

import { useTonConnectModal } from '@tonconnect/ui-react'
import { useTonAddress } from '@tonconnect/ui-react'
import TelegramAPI from '#/telegram'

import { getSettingValue } from '#/services/getSettingValue'
import useNotification from '#/hooks/useNotification'
import Notification from '#/components/alerts/Notification'
import TransactionCard from '#/components/cards/TransactionCard'

const tabs = [
  { name: 'withdraw', title: 'Withdraw' },
  { name: 'history', title: 'History' },
]

export default function Withdraw({ isOpen, setIsOpen }) {
  const { open } = useTonConnectModal()
  const userFriendlyAddress = useTonAddress()
  const { tonConnectUI } = useTonConnect()
  const [value, setValue] = useState(0)
  const { user, settings, withdraws, getWithdrawHistory, withdraw } = useUser()
  const { notification, isVisible, showNotification } = useNotification()

  const setPercentValue = (percent) => {
    TelegramAPI.Vibrate('heavy');
    if (user && user?.user?.balance) {
      let value = user?.user?.balance * (percent / 100);
      value = Math.floor(value * 100) / 100; 
      setValue(value);
    }
  };

  const [activeTab, setActiveTab] = useState('withdraw')
  const handleCloseModal = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (!value) {
      return setValue(0)
    }
    return setValue(parseFloat(value))
  }, [value])

  useEffect(() => {
    setActiveTab('withdraw')
    getWithdrawHistory()
  }, [isOpen])

  const withdrawButtonParams = useCallback(() => {
    const minimumValue = getSettingValue({ title: 'min_withdraw', settings, formatValue: false, float: true })
    const withdrawhalValue = parseFloat(value)
    if (!userFriendlyAddress) {
      return { text: 'Connect Wallet', action: open, style: {  } }
    }

    if (withdrawhalValue < minimumValue) {
      return { text: `Minimum ${minimumValue}`, action: () => {}, style: { opacity: 0.4 } }
    }

    if (withdrawhalValue > parseFloat(user?.user?.balance)) {
      return { text: `Not enough coins`, action: () => {}, style: { opacity: 0.4 } }
    }

    return {
      text: `Withdraw`,
      action: async () => {
        const result = await withdraw(value, userFriendlyAddress)
        setValue(0)
        if (result.success) {
          return showNotification('success', result.message, 3000)
        } else {
          return showNotification('error', result.message, 3000)
        }
      },
      style: {},
    }
  }, [settings, value])

  if (isOpen)
    return (
      <Modal title="Claim reward" onClose={handleCloseModal} modalHeight={'100vh'}>
        <TabsNavBar activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
        {activeTab === 'withdraw' && (
          <>
            <div className={styles.balanceContainer}>
              <div className={styles.imageContainer}>
                <img src={coinImage} alt="coin" class="coin" className={styles.image} />
              </div>
              <div className={styles.balance}>
                <div className={styles.title}>Your balance</div>
                <div className={styles.value}>{formatNumber(user.user.balance, 'balance')}</div>
                <div className={styles.totalMined}>Mined {formatNumber(user.totalMined)}</div>
              </div>
            </div>
            <div className={styles.fastValues}>
              <div className={styles.value} onClick={() => setPercentValue(25)}>
                25%
              </div>
              <div className={styles.value} onClick={() => setPercentValue(50)}>
                50%
              </div>
              <div className={styles.value} onClick={() => setPercentValue(100)}>
                100%
              </div>
            </div>
            <TextInput label={'Amount'} value={value} setValue={(e) => setValue(e.target.value)} type="float" />
            {!userFriendlyAddress && (
              <div className={styles.error}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  class="injected-svg"
                  role="img"
                  color="#ec0004">
                  <path
                    opacity="0.4"
                    d="M1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12Z"
                    fill="#ec0004"></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.7071 8.29292C16.0976 8.68345 16.0976 9.31662 15.7071 9.70713L13.4141 12L15.7071 14.2929C16.0976 14.6834 16.0976 15.3165 15.7071 15.7071C15.3166 16.0976 14.6835 16.0976 14.2929 15.7071L11.9998 13.4142L9.70708 15.7067C9.31655 16.0972 8.68338 16.0972 8.29287 15.7067C7.90236 15.3162 7.90238 14.683 8.29292 14.2925L10.5855 12L8.29292 9.70752C7.90238 9.31701 7.90236 8.68385 8.29287 8.29331C8.68338 7.90277 9.31655 7.90275 9.70708 8.29326L11.9998 10.5858L14.2929 8.29287C14.6835 7.90236 15.3166 7.90238 15.7071 8.29292Z"
                    fill="#ec0004"></path>
                </svg>
                <div>Wallet not connected</div>
              </div>
            )}
            {userFriendlyAddress && (
              <>
                <div className={styles.success}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    class="injected-svg"
                    role="img"
                    color="#7ed321">
                    <path
                      opacity="0.4"
                      d="M1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12Z"
                      fill="#7ed321"></path>
                    <path
                      d="M16.8775 8.02038C17.1425 8.50493 16.9645 9.11257 16.48 9.37756C15.1086 10.1275 13.8208 11.6975 12.8374 13.2032C12.3581 13.9372 11.9735 14.6207 11.709 15.1204C11.577 15.3697 11.4756 15.5721 11.4079 15.7105L11.3096 15.9159C11.1551 16.2536 10.8261 16.4782 10.4553 16.4987C10.0844 16.5191 9.73267 16.3323 9.54198 16.0135C9.23126 15.494 8.73753 15.0198 8.27997 14.6581C8.05764 14.4823 7.85752 14.3437 7.71471 14.2502L7.50442 14.1187C7.02493 13.8449 6.85797 13.2344 7.13152 12.7548C7.40513 12.275 8.01585 12.1079 8.49559 12.3815L8.81063 12.5772C8.99282 12.6965 9.2427 12.8697 9.52038 13.0892C9.73258 13.257 9.96984 13.4587 10.2094 13.6911C10.4662 13.2297 10.7872 12.685 11.1629 12.1096C12.1796 10.5529 13.6917 8.62286 15.5204 7.62282C16.0049 7.35782 16.6125 7.53582 16.8775 8.02038Z"
                      fill="#7ed321"></path>
                  </svg>
                  <div>Wallet: {shortWalletAddress(userFriendlyAddress)}</div>
                </div>
                <div onClick={() => tonConnectUI.disconnect()} className={styles.disconnect}>
                  Disconnect
                </div>
              </>
            )}
            <div className={styles.withdrawButton}>
              <PrimaryButton
                className={styles.button}
                onClick={withdrawButtonParams().action}
                style={withdrawButtonParams().style}>
                {withdrawButtonParams().text}
              </PrimaryButton>
            </div>
          </>
        )}
        {activeTab === 'history' && (
          <div className={styles.withdrawhs}>
            {withdraws && withdraws.map((item, index) => <TransactionCard key={index} item={item} />)}
          </div>
        )}
        <Notification notification={notification} isVisible={isVisible} />
      </Modal>
    )
}
