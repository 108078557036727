import axios from 'axios'
import { SERVER_LINK } from '#/data/serverLink'
import TelegramAPI from '#/telegram'

const apiBaseUrl = `${SERVER_LINK}/api`

const initData = TelegramAPI.getInitData() 
const user = TelegramAPI.getInitDataUnsafe()
const user_id =  user?.user?.id 


const axiosInstance = axios.create({
  headers: {
    'ngrok-skip-browser-warning': 'true',
    'Authorization': `Bearer ${initData}`
  },
})

class UserAPI {
  static async getUserInfo() {
    try {
      const response = await axiosInstance.get(`${apiBaseUrl}/user/${user_id}`)
      return response.data
    } catch (e) {
      console.error('Error getting user info:', e)
    }
  }

  static async getUserTop(filter, ratingType = 'daily') {
    try {
      const response = await axiosInstance.get(`${apiBaseUrl}/user/top/${filter}/${ratingType}`);
      return response.data;
    } catch (e) {
      console.error('Error getting top:', e);
    }
  }

  static async withdraw(amount, wallet) {
    try {
      const response = await axiosInstance.post(`${apiBaseUrl}/user/${user_id}/withdraw`, { amount, wallet })
      return response.data
    } catch (e) {
      console.error('Error with withdraw: ')
    }
  }

  static async withdraws() {
    try {
      const response = await axiosInstance.get(`${apiBaseUrl}/user/${user_id}/withdraws`)
      return response.data
    } catch (e) {
      console.error('Error with withdraw: ')
    }
  }

  static async refferals() {
    try {
      const response = await axiosInstance.get(`${apiBaseUrl}/user/${user_id}/refferals`)
      return response.data
    } catch (e) {
      console.log('Error')
    }
  }
}

class MiningAPI {
  static async getInfo() {
    try {
      const response = await axiosInstance.get(`${apiBaseUrl}/mining-info`)
      return response.data
    } catch (e) {
      console.error('Error getting mining info:', e)
    }
  }

  static async claimReward() {
    try {
      const response = await axiosInstance.post(`${apiBaseUrl}/user/${user_id}/claim-mining`)
      return response.data
    } catch (e) {
      console.error('Error claiming reward:', e)
    }
  }

  static async continueMining() {
    try {
      const response = await axiosInstance.post(`${apiBaseUrl}/user/${user_id}/continue-mining`)
      return response.data
    } catch (e) {
      console.error('Error claiming reward:', e)
    }
  }
}

class ShopAPI {
  // Получение доступных бустеров
  static async getAviableBoosters() {
    try {
      const response = await axiosInstance.get(`${apiBaseUrl}/shop/boosters`)
      return response.data
    } catch (e) {
      console.error(e)
      throw new Error('Failed to fetch boosters')
    }
  }

  // Получение доступных видеокарт
  static async getAviableVideocards() {
    try {
      const response = await axiosInstance.get(`${apiBaseUrl}/shop/cards`)
      return response.data
    } catch (e) {
      console.error(e)
      throw new Error('Failed to fetch videocards')
    }
  }

  // Покупка бустера за монеты
  static async buyBooster(itemId, payment = 'coin', wallet) {
    try {
      const response = await axiosInstance.post(`${apiBaseUrl}/shop/${user_id}/buyBooster`, {
        item_id: itemId,
        payment,
        wallet,
      })
      return response.data
    } catch (e) {
      console.error(e)
      throw new Error('Failed to buy booster')
    }
  }

  // Покупка видеокарты за монеты
  static async buyVideocard(itemId, count, payment = 'coin', wallet) {
    try {
      const response = await axiosInstance.post(`${apiBaseUrl}/shop/${user_id}/buyVideocard`, {
        item_id: parseInt(itemId),
        count: count,
        payment,
        wallet,
      })
      return response.data
    } catch (e) {
      console.error(e)
      throw new Error('Failed to buy videocard')
    }
  }
}

class TasksAPI {
  static async getTasks() {
    try {
      const response = await axiosInstance.get(`${apiBaseUrl}/tasks/${user_id}`)
      return response.data
    } catch (e) {
      console.log('Error with getting tasks:', e)
    }
  }

  static async completeTask(task_id) {
    try {
      const response = await axiosInstance.post(`${apiBaseUrl}/tasks/${task_id}/complete-link`, { user_id: user_id })
      return response.data
    } catch (e) {
      console.log('Error with getting tasks:', e)
    }
  }

  static async verifyTaskCompletion(task_id) {
    try {
      const response = await axiosInstance.post(`${apiBaseUrl}/tasks/${task_id}/confirm`, { user_id: user_id })
      return response.data
    } catch (e) {
      console.log('Error with getting tasks:', e)
    }
  }
}

class SystemAPI {
  static async getSettings() {
    try {
      const response = await axiosInstance.get(`${apiBaseUrl}/settings`)
      return response.data
    } catch (e) {
      return { success: false, message: 'Server Error. Please reload webapp' }
    }
  }
}

class SettingsAPI {
  static async openProfile() {
    TelegramAPI.Vibrate('heavy')
    TelegramAPI.OpenLink(`${SERVER_LINK}/api/redirect?user_id=${user_id}`)
  }

  static async getWalletUSDTbalance(owner_address, jetton_master_address) {
    const net = 'main'
    try {
      const response = await axios.get(
        `https://${
          net === 'test' ? 'testnet.' : ''
        }toncenter.com/api/v3/jetton/wallets?owner_address=${owner_address}&jetton_address=${jetton_master_address}&limit=10&offset=0`
      )
      return response.data
    } catch (e) {
      console.log('Error with server')
    }
  }
}

class DepositAPI {
  // static async checkDeposit(searchedMessage) {
  //   try {
  //     const response = await axiosInstance.post(`${apiBaseUrl}/deposit`, {
  //       user_id: user_id,
  //       searchedMessage: searchedMessage,
  //     })
  //     return response.data
  //   } catch (e) {
  //     console.error('Error checking deposit:', e)
  //     throw new Error('Failed to check deposit')
  //   }
  // }

  static async getDepositHistory() {
    try {
      const response = await axiosInstance.get(`${apiBaseUrl}/deposits/history`, {
        params: { user_id: user_id },
      })
      return response.data
    } catch (e) {
      console.error('Error fetching deposit history:', e)
      throw new Error('Failed to fetch deposit history')
    }
  }
}

class API {
  static user = UserAPI
  static mining = MiningAPI
  static tasks = TasksAPI
  static shop = ShopAPI
  static system = SystemAPI
  static settings = SettingsAPI
  static deposit = DepositAPI 
}

export default API
