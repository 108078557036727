// Slider.jsx
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import styles from './Slider.module.css'
import { CoinIcon, LeftSliderIcon, RightSliderIcon } from '#/assets/icons'
import { modes } from '#/data/data'
import { formatNumber } from '#/services/helpers'
import { cardsImages } from '#/data/data'
import { useUser } from '#/context/userContext'
import ImageLoader from '#/components/loaders/ImageLoader'
import useLoadImage from '#/hooks/useLoadImage'

function Slider({ cards, blockStyle, modeIndex, setModeIndex, count }) {
  const { user } = useUser()
  const [animationClass, setAnimationClass] = useState('')
  const [isAnimating, setIsAnimating] = useState(false)

  const { isLoaded, isLoadedEnd, handleLoadImage } = useLoadImage(500)

  //Получение доступныз стилей видеокарт
  const validModes = useMemo(() => {
    return Object.entries(modes)
      .filter(([key, mode]) => mode !== null && key !== 'null')
      .map(([key, mode]) => ({ ...mode, id: Number(key) }));
  }, [modes]);

  // Активная картаа
  const activeCard = useMemo(() => cards.find((card) => card.id === modeIndex), [cards, modeIndex])
  const currentMode = validModes[modeIndex];


  // Универсальный обработчик переключения слайдов
  const handleNavigate = useCallback(
    (direction) => {
      if (isAnimating) return;
      setIsAnimating(true);
      const length = validModes.length ;
      const newIndex =
        (modeIndex + direction + length) % length;
      setModeIndex(validModes[newIndex].id);
      setAnimationClass(
        styles[direction === 1 ? 'slide-enter-right' : 'slide-enter-left']
      );
      setTimeout(() => {
        setAnimationClass('');
        setIsAnimating(false);
      }, 500);
    },
    [isAnimating, modeIndex, setModeIndex, validModes]
  );

  // Мемоизация элементов навигации
  const sliderNavigation = useMemo(
    () => (
      <div className={styles.slider__navigation}>
        <button onClick={() => handleNavigate(-1)} className={styles.button}>
          <LeftSliderIcon additionalColor={currentMode?.navigation.prev} />
        </button>
        <button onClick={() => handleNavigate(1)} className={styles.button}>
          <RightSliderIcon additionalColor={currentMode?.navigation.next} />
        </button>
      </div>
    ),
    [handleNavigate, currentMode]
  );

  // Предзагрузка всех изображений при монтировании компонента
  useEffect(() => {
    cardsImages.forEach((imageObj) => {
      const img = new Image()
      img.src = imageObj.image
    })
  }, [])

  // Обработчик загрузки изображения
  const handleImageLoad = useCallback(() => {
    handleLoadImage()
  }, [handleLoadImage])

  if (!user) return null

  return (
    <>
      {/* <ImageLoader isLoadedEnd={isLoadedEnd} isLoaded={isLoaded} /> */}
      <div className={styles.mainContainer}>
        {sliderNavigation}
        <div className={`${styles.container} ${animationClass}`}>
          <div className={styles.header}>{activeCard?.name}</div>
          <div className={`${styles.slider_image} ${animationClass}`}>
            <div className={styles.backGradient} style={blockStyle} key={modeIndex}></div>
            <img
              src={cardsImages[modeIndex]?.image}
              className={styles.videoCard}
              alt="card"
              onLoad={handleImageLoad}
            />
          </div>
          <div className={styles.cardInfo}>
            <div className={styles.cardInfo_item}>
              <div className={styles.item_text}>{formatNumber(parseFloat(activeCard?.ghs) * count)}</div>
              <div className={styles.item_title}>GH/s</div>
            </div>
            <div className={styles.cardInfo_item}>
              <div className={styles.item_text}>{formatNumber(parseFloat(activeCard?.coinsPerHour) * count)}</div>
              <div className={styles.item_title}>
                <CoinIcon height={10} />/ hour
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(Slider)
