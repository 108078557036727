import React, { useEffect, useState, useCallback } from 'react'
import styles from './InfoSetcion.module.css'

import { useUser } from '#/context/userContext'
import { formatNumber } from '#/services/helpers'

import BodyCell from '#/components/tables/cells/BodyCell'
import HeaderCell from '#/components/tables/cells/HeaderCell'
import TotalCell from '#/components/tables/cells/TotalCell'
import BodyRow from '#/components/tables/rows/BodyRow'
import HeaderRow from '#/components/tables/rows/HeaderRow'
import FooterRow from '#/components/tables/rows/FooterRow'
import TFooter from '#/components/tables/sections/TFooter'
import Tbody from '#/components/tables/sections/Tbody'
import THead from '#/components/tables/sections/THead'
import CustomTable from '#/components/tables'
import PrimaryButton from '#/components/buttons/PrimaryButton/PrimaryButton'
import { FiltersIcon } from '#/assets/icons'

const InfoSetcion = ({ mode, handleOpenShop, handleClaim }) => {
  const { user, groupedCards, getFormattedTimer } = useUser()

  const [filter, setFilter] = useState('mined')
  const [useTH, setUseTH] = useState(false) // Флаг для замены GH на TH
  const handleFilterButtonCLick = () => {
    setFilter(filter === 'mined' ? 'minutes' : filter === 'minutes' ? 'hours' : 'mined')
  }

  const [totals, setTotals] = useState({ count: 0, ghs: 0, rewards: 0 })

  useEffect(() => {
    let totalCount = 0
    let totalGhs = 0
    let totalRewards = 0
    let totalCoinsPerHour = 0

    if (user && groupedCards) {
      groupedCards.forEach(({ count, ghs, reward, coinsPerHour }) => {

        totalCount += count
        totalGhs += ghs * count
        totalRewards += parseFloat(reward)
        totalCoinsPerHour += parseFloat(coinsPerHour) * count

      })

      // Если общее количество GH/s или одно из значений больше 1000, переключаемся на TH/s
      if (totalGhs > 1000 || groupedCards.some(({ ghs }) => ghs > 1000)) {
        setUseTH(true)
      } else {
        setUseTH(false)
      }
    }

    setTotals({ count: totalCount, ghs: totalGhs, rewards: totalRewards, coinsPerHour: totalCoinsPerHour })
  }, [user, groupedCards])

  const filteredData = useCallback(
    (data, total = false) => {
      const multiplier = useTH ? 1000 : 1 // Преобразование GH в TH
      switch (filter) {
        case 'minutes':
          return total ? (data.ghs * 60) / multiplier : (data.ghs * data.count * 60) / multiplier
        case 'hours':
          return total ? (data.ghs * 3600) / multiplier : (data.ghs * data.count * 3600) / multiplier
        default:
          return total ? data.ghs / multiplier : (data.ghs * data.count) / multiplier
      }
    },
    [filter, useTH]
  )

  const buttonText = () => {
    if (filter === 'mined') {
      return 'Mined'
    } else if (filter === 'minutes') {
      return 'Per minute'
    } else {
      return 'Per hour'
    }
  }

  const tableCoinValue = (item, total = false) => {
    if (filter === 'mined') {
      return total ? formatNumber(totals.rewards) : formatNumber(parseFloat(item.reward))
    } else if (filter === 'minutes') {
      return total ? formatNumber(totals.coinsPerHour / 60) : formatNumber((item.coinsPerHour / 60) * item.count)
    } else {
      return total ? formatNumber(totals.coinsPerHour) : formatNumber(item.coinsPerHour * item.count)
    }
  }

  return (
    <div className={styles.infoSection}>
      <div className={styles.info_buttons}>
        <PrimaryButton onClick={handleOpenShop} className={styles.storeButton}>
          GPU Store
        </PrimaryButton>
        <div className={styles.filtersButton} onClick={handleFilterButtonCLick}>
          <FiltersIcon additionalColor={'#0097EC'} /> {buttonText()}
        </div>
      </div>

      <CustomTable>
        <colgroup>
          <col style={{ width: '50%' }} />
          <col style={{ width: '25%' }} />
          <col style={{ width: '25%' }} />
        </colgroup>
        <THead>
          <HeaderRow>
            <HeaderCell>Your GPUs</HeaderCell>
            <HeaderCell>
              {filter === 'mined'
                ? useTH
                  ? 'TH/s'
                  : 'GH/s'
                : filter === 'minutes'
                ? useTH
                  ? 'TH/m'
                  : 'GH/m'
                : useTH
                ? 'TH/h'
                : 'GH/h'}
            </HeaderCell>
            <HeaderCell>Coins</HeaderCell>
          </HeaderRow>
        </THead>
        <Tbody>
          {user &&
            groupedCards.map((item, index) => (
              <BodyRow key={index}>
                <BodyCell>
                  {item.name} × {item.count}
                </BodyCell>
                <BodyCell>{formatNumber(filteredData(item))}</BodyCell>
                <BodyCell>{tableCoinValue(item)}</BodyCell>
              </BodyRow>
            ))}
        </Tbody>
        <TFooter>
          <FooterRow>
            <TotalCell>
              <span className={styles.total__span}>Total:</span> {totals.count} GPUs
            </TotalCell>
            <TotalCell>{formatNumber(filteredData(totals, true))}</TotalCell>
            <TotalCell>{tableCoinValue(totals, true)}</TotalCell>
          </FooterRow>
        </TFooter>
      </CustomTable>

      <PrimaryButton style={{ opacity: user?.miningInfo?.canClaimReward ? 1 : 0.1 }} onClick={handleClaim}>
        <div className={styles.collectButton}>Claim </div>
      </PrimaryButton>
      <div className={styles.timer}>
        {getFormattedTimer()} until your tokens are burned
      </div>
    </div>
  )
}

export default InfoSetcion
