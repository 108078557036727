import React from 'react'
import styles from './HighestCard.module.css'
import duck from '#/assets/duck_nameplate.gif'
import { useUser } from '#/context/userContext'
import { cardsImages } from '#/data/data'
import useLoadImage from '#/hooks/useLoadImage'
import ImageLoader from '#/components/loaders/ImageLoader'

const HighestCard = ({ blockStyle, modeIndex, onClick }) => {
  const { user } = useUser()
  const { isLoaded, isLoadedEnd, handleLoadImage } = useLoadImage(500)

  return (
    <div className={styles.mainContainer} onClick={onClick}>
      <ImageLoader isLoadedEnd={isLoadedEnd} isLoaded={isLoaded} />
      <div className={`${styles.container}`}>
        <div className={`${styles.slider_image}`}>
          <div className={styles.backGradient} style={blockStyle || {}} key={modeIndex}></div>
          {!user.card && user.card !== 0 && (
            <div className={styles.imageNotExist__container}>
              <div className={styles.imageNotExist}>
                <img src={duck} className={styles.duck} height={150} alt="duck" onLoad={handleLoadImage} />
              </div>
            </div>
          )}
          {typeof user.card === 'number' && (
            <img
              src={!user?.miningInfo.miningActive ? cardsImages[user.card].image : cardsImages[user.card].gif}
              alt="card"
              className={styles.videocard}
              style={{ width: user.card === 0 ? '50vw' : '60vw', opacity: 1 }}
              onLoad={handleLoadImage}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default HighestCard
