import { DepositIcon } from '#/assets/icons'
import { formatNumber, shortWalletAddress } from '#/services/helpers'
import TelegramAPI from '#/telegram'
import styles from './TransactionCard.module.css'

export function formatDateWithoutYear(isoDate) {
  const options = {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }

  const date = new Date(isoDate)
  return date.toLocaleString('en-US', options)
}

export default function TransactionCard({ item, type = 'withdraw' }) {
  return (
    <div className={`${styles.container} `}>
      <div className={styles.left}>
        <div>
          <div
            className={styles.title}
            style={
              item.status === 'completed'
                ? { color: '#7ed321' }
                : item.status === 'rejected'
                ? { color: '#ec0004' }
                : {}
            }>
            <div>
              <DepositIcon width={16} height={16} />
            </div>
            <div>
              {type === 'withdraw' ? 'Withdrawal' : 'Deposit'} {formatNumber(item.amount)}
            </div>
          </div>
          {type === 'withdraw' && <div className={styles.wallet}>{shortWalletAddress(item.wallet)}</div>}
          {/* {type === 'deposit' && (
            <button
            className={styles.trace_id}
              onClick={() => {
                TelegramAPI.Vibrate('heavy')
                TelegramAPI.OpenLink(`https://tonscan.org/ru/tx/${item.trace_id}`)
              }}>
              View Transaction
            </button>
          )} */}
        </div>
      </div>
      <div className={styles.right}>
        <div>
          {item.status === 'completed' ||
            (type === 'deposit' && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                class="injected-svg"
                role="img"
                color="#7ed321">
                <path
                  opacity="0.4"
                  d="M1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12Z"
                  fill="#7ed321"></path>
                <path
                  d="M16.8775 8.02038C17.1425 8.50493 16.9645 9.11257 16.48 9.37756C15.1086 10.1275 13.8208 11.6975 12.8374 13.2032C12.3581 13.9372 11.9735 14.6207 11.709 15.1204C11.577 15.3697 11.4756 15.5721 11.4079 15.7105L11.3096 15.9159C11.1551 16.2536 10.8261 16.4782 10.4553 16.4987C10.0844 16.5191 9.73267 16.3323 9.54198 16.0135C9.23126 15.494 8.73753 15.0198 8.27997 14.6581C8.05764 14.4823 7.85752 14.3437 7.71471 14.2502L7.50442 14.1187C7.02493 13.8449 6.85797 13.2344 7.13152 12.7548C7.40513 12.275 8.01585 12.1079 8.49559 12.3815L8.81063 12.5772C8.99282 12.6965 9.2427 12.8697 9.52038 13.0892C9.73258 13.257 9.96984 13.4587 10.2094 13.6911C10.4662 13.2297 10.7872 12.685 11.1629 12.1096C12.1796 10.5529 13.6917 8.62286 15.5204 7.62282C16.0049 7.35782 16.6125 7.53582 16.8775 8.02038Z"
                  fill="#7ed321"></path>
              </svg>
            ))}
          {item.status === 'rejected' && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              class="injected-svg"
              role="img"
              color="#ec0004">
              <path
                opacity="0.4"
                d="M1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12Z"
                fill="#ec0004"></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.7071 8.29292C16.0976 8.68345 16.0976 9.31662 15.7071 9.70713L13.4141 12L15.7071 14.2929C16.0976 14.6834 16.0976 15.3165 15.7071 15.7071C15.3166 16.0976 14.6835 16.0976 14.2929 15.7071L11.9998 13.4142L9.70708 15.7067C9.31655 16.0972 8.68338 16.0972 8.29287 15.7067C7.90236 15.3162 7.90238 14.683 8.29292 14.2925L10.5855 12L8.29292 9.70752C7.90238 9.31701 7.90236 8.68385 8.29287 8.29331C8.68338 7.90277 9.31655 7.90275 9.70708 8.29326L11.9998 10.5858L14.2929 8.29287C14.6835 7.90236 15.3166 7.90238 15.7071 8.29292Z"
                fill="#ec0004"></path>
            </svg>
          )}
          {item.status === 'pending' && <div className={styles.spin}></div>}
        </div>
        <div className={styles.date}>
          {formatDateWithoutYear(type === 'withdraw' ? item.withdrawal_date : item.transaction_date)}
        </div>
      </div>
    </div>
  )
}
