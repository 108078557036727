import React, { useEffect, useState } from 'react'
import styles from './BalanceContainer.module.css'
import { LightingIcon, RocketIcon, BalanceCoinIcon } from '#/assets/icons'
import { formatNumber } from '#/services/helpers'
import { useUser } from '#/context/userContext'
import rocket from '#/assets/gifs/rocket.gif'
import boosterGif from '#/assets/gifs/booster.gif'
import { boosterIcons } from '#/data/data'

export default function BalanceContainer({ onClick, balance }) {
  const [buyed1, setBuyed1] = useState(false)
  const [buyed2, setBuyed2] = useState(false)
  const { user } = useUser()

  useEffect(() => {
    if (user) {
      const find1booster = user?.boosters.some((item) => item.item_id === 0)
      const find2booster = user?.boosters.some((item) => item.item_id === 1)
      setBuyed1(find1booster)
      setBuyed2(find2booster)
    }
  }, [user])

  return (
    <div className={styles.balanceContainer} onClick={onClick}>
      <div className={styles.balanceContainer__left}>
        {buyed1 ? <img src={rocket} width={24} height={24} /> : <RocketIcon width="32" height="32" />}
      </div>
      <div className={styles.balanceContainer__balance}>
        <BalanceCoinIcon width="20" height="20" /> <div>{formatNumber(balance, 'balance')}</div>
      </div>
      <div className={styles.balanceContainer__right}>
        {buyed2 ? <img src={boosterGif} width={24} height={24} /> : <LightingIcon width="24" height="24" />}
      </div>
    </div>
  )
}
