import { useState, useCallback } from 'react';

const useNotification = () => {
  const [notification, setNotification] = useState(null); // {type, message}
  const [isVisible, setIsVisible] = useState(false); // для анимации показа/скрытия
  const [isFirstRender, setIsFirstRender] = useState(false); // для задержки показа

  const showNotification = useCallback((type, message, timeout = 3000) => {
    setNotification({ type, message });
    setIsFirstRender(true); // Устанавливаем флаг для первой отрисовки

    // Используем небольшой таймаут для задержки показа
    setTimeout(() => {
      setIsVisible(true); // Запускаем анимацию показа
    }, 10);

    // Скрытие уведомления после заданного времени
    setTimeout(() => {
      setIsVisible(false); // Запускаем анимацию скрытия
    }, timeout);
  }, []);

  return { notification, isVisible, showNotification };
};

export default useNotification;