import { useUser } from '#/context/userContext'
import { formatNumber } from './helpers'

export const getSettingValue = ({ settings, title, formatValue = false, float = false }) => {
  const result = settings.find((item) => item.title === title)
  if (result?.value) {
    let value = result.value

    if (float) {
      value = parseFloat(value)
    }

    if (formatValue) {
      value = formatNumber(value)
    }
    return value
  }
  return 'error'
}
