import React from 'react'
import styles from './WalletPage.module.css'
import BottomNavigation from '#/components/navigation/BottomNavigation/BottomNavigation'
import wallet from '#/assets/wallet.gif'
import { TonConnectButton } from '@tonconnect/ui-react'
// import { useUser } from '#/context/userContext'

export default function WalletPage() {
  // const { user } = useUser()
  return (
    <div className={styles.container}>
      <div className={styles.pageHeader}>Connect Wallet</div>
      <img src={wallet} className={styles.walletGif} />
      <TonConnectButton />
      <div className={styles.pageDescription}>
        Connect your wallet using the secure TON Connect protocol. It’s fast and convenient. You’ll love it
      </div>
      {/* <div className={styles.totalMined}>
        <div>
          <h1>Mined</h1>
          <p>{user.totalMined}</p>
        </div>

        <div>
          <h1>Total users mined</h1>
          <p>{user.totalMined}</p>
        </div>
      </div> */}
      <BottomNavigation activeTab={'wallet'} />
    </div>
  )
}
