import React, { useEffect, useState, useCallback } from 'react'
import { useSwipeable } from 'react-swipeable'
import TelegramAPI from '#/telegram'
import { useUser } from '#/context/userContext'
import Banner from './components/Banner/Banner'
import FriendCard from './components/FriendCard/FriendCard'
import TabsNavBar from '#/components/navigation/TabsNavBar'
import styles from './FriendsPage.module.css'
import Notification from '#/components/alerts/Notification'
import BottomNavigation from '#/components/navigation/BottomNavigation/BottomNavigation'
import PrimaryButton from '#/components/buttons/PrimaryButton/PrimaryButton'
import useNotification from '#/hooks/useNotification'
import { ClipboardIcon, CoinIcon, FriendsArrowIcon, FriendsIcon, GroupIcon, HornIcon } from '#/assets/icons'
import duck from '#/assets/duck.gif'
import { formatNumber } from '#/services/helpers'

const tabs = [
  { name: 'invite', title: 'Invite' },
  { name: 'friends', title: 'Your friends' },
]

const ITEMS_PER_PAGE = 10;

export default function FriendsPage() {
  const { user, settings, refferals, fetchRefferals } = useUser()
  const [activeTab, setActiveTab] = useState('invite')
  const { notification, isVisible, showNotification } = useNotification()
  const [currentPage, setCurrentPage] = useState(1);

  // Функция для переключения на следующую страницу
  const handleNextPage = () => {
    if (currentPage < Math.ceil(refferals?.refferals.length / ITEMS_PER_PAGE)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Функция для переключения на предыдущую страницу
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Получаем рефералов для текущей страницы
  const paginatedRefferals = refferals?.refferals ? refferals?.refferals?.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  ) : [];

  const handleSwipeLeft = useCallback(() => {
    const nextTabIndex = Math.min(tabs.findIndex((tab) => tab.name === activeTab) + 1, tabs.length - 1)
    setActiveTab(tabs[nextTabIndex].name)
  }, [activeTab])

  const handleSwipeRight = useCallback(() => {
    const prevTabIndex = Math.max(tabs.findIndex((tab) => tab.name === activeTab) - 1, 0)
    setActiveTab(tabs[prevTabIndex].name)
  }, [activeTab])

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  useEffect(() => {
    if (activeTab === 'friends') {
      fetchRefferals()
      TelegramAPI.ShowBackButton()
      TelegramAPI.OnBackButtonClick(() => setActiveTab('invite'))
    } else {
      TelegramAPI.HideBackButton()
    }
  }, [activeTab])

  const [copied, setCopied] = useState(false)
  const handleCopyLink = (text) => {
    setCopied(true)
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showNotification('success', 'The referral link has been successfully copied to your clipboard.', 3000)
      })
      .catch((err) => {
        showNotification('error', 'Failed to copy the link. Please try again', 3000)
      })
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  const handleShare = () => {
    const tg = TelegramAPI.getInitDataUnsafe()

    const referralText =
      tg?.user?.language_code === 'ru'
        ? `
⚡️Присоединяйся к MineX⚡️
— новому приложению для майнинга на виртуальных видеокартах

👨‍💻 Выбирай доступные токены и добывай их

🔥 Получай до 250 $MineX за каждого друга и используй многоуровневую реферальную систему чтобы увеличить пассивный доход

💰Продавай добытые токены или докупай еще в любой момент на бирже

Сайт: MineX.cx
Туториал: MineX.cx/hello
Приложение:  ${user?.referal_link}
    `
        : `
⚡️Join MineX today⚡️
— the cutting-edge app for mining with virtual GPUsÏƒ

👨‍💻 Select your preferred tokens and start mining instantly

🔥 Earn up to 250 $MineX for every friend you refer and boost your passive income with our multi-level referral system

💰 Sell your mined tokens or purchase more anytime on the exchange

Website: MineX.cx
Tutorial: MineX.cx/hello
App: ${user?.referal_link}
    `.trim()

    const encodedText = encodeURIComponent(referralText)
    const telegramLink = `https://t.me/share/url?url= &text=${encodedText}`

    TelegramAPI.OpenTelegramLink(telegramLink)
  }

  if (user) {
    return (
      <div {...swipeHandlers} className={styles.container}>
        <TabsNavBar
          activeTab={activeTab}
          tabs={tabs}
          setActiveTab={setActiveTab}
          buttonProps={{ style: { padding: '12px 0' } }}
        />
        <div className={styles.tabs}>
          {activeTab === 'invite' && (
            <div className={styles.contentContainer}>
              <div className={styles.pageHeader}>Invite friends</div>
              <div className={styles.pageDescription}>Both you and your friend will receive bonuses</div>
              <div className={styles.banners}>
                <Banner />
              </div>
              <div className={styles.buttonsContainer}>
                <PrimaryButton icon={<HornIcon height={18} width={18} />} onClick={handleShare}>
                  Invite friend
                </PrimaryButton>
                <PrimaryButton
                  icon={<ClipboardIcon width={24} height={24} />}
                  style={{ background: '#0097EC' }}
                  onClick={() => handleCopyLink(user?.referal_link)}
                />
              </div>
            </div>
          )}
          {activeTab === 'friends' && (
            <div className={styles.contentContainer}>
              <div className={styles.statsBanner}>
                <div className={styles.item}>
                  <div className={styles.item__header}>Total friends refered:</div>
                  <div className={`${styles.statsBanner__container} ${styles.totalCount}`}>
                    <div className={`${styles.statsBanner__container_icon} ${styles.totalCount}`}>
                      <GroupIcon height={14} />
                    </div>
                    {refferals?.totalRefferals >= 0 ? refferals?.totalRefferals : 0}
                  </div>
                </div>

                <div className={styles.item}>
                  <div className={styles.item__header}>Total earned:</div>
                  <div className={`${styles.statsBanner__container} ${styles.totalRewards}`}>
                    <div className={`${styles.statsBanner__container_icon} ${styles.totalRewards}`}>
                      <CoinIcon height={14} />
                    </div>
                    {formatNumber(refferals?.reward && refferals?.refRewards ? parseFloat(refferals?.reward) + parseFloat(refferals?.refRewards) : 0)}
                  </div>
                </div>
              </div>

              <div className={styles.navigationBlock}>
                <div className={styles.navigationBlock__left} onClick={handlePrevPage}>
                  <FriendsArrowIcon />
                </div>
                <div className={styles.navigationBlock__page}>
                  {currentPage}/{Math.ceil(refferals?.refferals?.length ? refferals?.refferals?.length / ITEMS_PER_PAGE : 1)} ({(currentPage - 1) * ITEMS_PER_PAGE + 1}-
                  {Math.min(currentPage * ITEMS_PER_PAGE, refferals?.refferals?.length ? refferals?.refferals?.length : 0)})
                </div>
                <div className={styles.navigationBlock__right} onClick={handleNextPage}>
                  <FriendsArrowIcon />
                </div>
              </div>

              <div className={styles.friendsContainer}>
              {paginatedRefferals && paginatedRefferals.length > 0 ? (
                  paginatedRefferals.map((friend, index) => <FriendCard user={friend} key={index} />)
                ) : (
                  <div className={styles.errorContainer}>
                    <img src={duck} className={styles.duck} />
                    <div className={styles.errorTitle}>Friends not found</div>
                    <div className={styles.errorDescruption}>
                      It seems like you’re all alone here. Copy your referral link and invite friends to join. The more friends you bring, the more you can earn together.
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <BottomNavigation activeTab="friends" />
        <Notification notification={notification} isVisible={isVisible} />
      </div>
    )
  }
}
