import React from 'react'
import styles from './Banner.module.css'
import { CoinIcon, CoinsIcon, FriendsIncome, GiftIcon, LinkIcon } from '#/assets/icons'
import { formatNumber } from '#/services/helpers'
import { useUser } from '#/context/userContext'
import { getSettingValue } from '#/services/getSettingValue'

const Banner = ({}) => {
  const { settings } = useUser()
  return (
    <div>
      <div className={styles.bannerTitle}>How its works?</div>
      <div className={styles.container}>
        <div className={styles.line}>
          <div className={styles.icon}>
            <LinkIcon />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Share link</div>
            <div className={styles.description}>Invite your friend via link.</div>
          </div>
        </div>
        <div className={styles.line}>
          <div className={styles.icon}>
            <GiftIcon />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Invite a friend</div>
            <div className={styles.description}>
              Receive{' '}
              <span className={styles.highlight}>
                {' '}
                <CoinIcon height={10} /> +
                {getSettingValue({ title: 'friend_reward', settings, formatValue: true, float: true })}
              </span>{' '}
              for you and your friend. For users with Telegram Premium{' '}
              <span className={styles.highlight}>
                <CoinIcon height={10} /> +{getSettingValue({ title: 'premium_friend_reward', settings, formatValue: true, float: true })}
              </span>{' '}
            </div>
          </div>
        </div>
        <div className={styles.line}>
          <div className={styles.icon}>
            <CoinsIcon width={24} height={24} />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Receive comission</div>
            <div className={styles.description}>
              Receive{' '}
              <span className={styles.highlight}>
                <CoinIcon height={10} /> +{getSettingValue({ title: 'comission_friend_reward', settings, formatValue: true, float: true })}%
              </span>{' '}
              for your friends mining income.
            </div>
          </div>
        </div>
        <div className={styles.line}>
          <div className={styles.icon}>
            <FriendsIncome width={24} />
          </div>
          <div className={styles.content}>
            <div className={styles.title}>Boost your income</div>
            <div className={styles.description}>
            Earn {' '}
              <span className={styles.highlight}>
                <CoinIcon height={10} /> +{getSettingValue({ title: 'comission_friend_friends_reward', settings, formatValue: true, float: true })}%
              </span>{' '}
              from the mining income of your friends' referrals
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
