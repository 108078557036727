import React, { useEffect } from 'react'
import styles from './TasksPage.module.css'
import TelegramAPI from '#/telegram'
import { useUser } from '#/context/userContext'
import useNotification from '#/hooks/useNotification'
import TaskCard from './components/TaskCard/TaskCard'
import Notification from '#/components/alerts/Notification'
import BottomNavigation from '#/components/navigation/BottomNavigation/BottomNavigation'

export default function TasksPage() {
  const { getTasks, tasks, verifyTask, completeTask, user, updateUserInfo } = useUser()
  const { notification, isVisible, showNotification } = useNotification()

  const handleTaskClick = async (task) => {
    if (task.completing_status === 'viewed') {
      TelegramAPI.Vibrate('heavy')
      const result = await verifyTask(task.task_id)
      if (result.success) {
        showNotification('success', result.message, 3000)
        setTimeout(() => {
          updateUserInfo()
        }, 1000)
        return 
      } else {
        return showNotification('error', result.message, 3000)
      }
    } else if (task.completing_status === 'completed') {
      return
    } else {
      TelegramAPI.Vibrate('heavy')
      if (task.type === 'telegram') {
        TelegramAPI.OpenTelegramLink(task.link)
      } else if (task.type !== 'shop' && task.type !== 'friends') {
        TelegramAPI.OpenLink(task.link)
      }
      const result = await completeTask(task.task_id)
      showNotification('success', result.message, 3000)
      setTimeout(() => {
        updateUserInfo()
      }, 300)
      return 
    }
  }

  useEffect(() => {
    if (user) {
      getTasks()
    }
  }, [user])

  return (
    <div className={styles.container}>
      <div className={styles.pageHeader}>Complete tasks to earn more </div>
      <div className={styles.tasksContainer}>
        {tasks &&
          tasks.map((task, index) => <TaskCard task={task} key={index} onClick={() => handleTaskClick(task)} />)}
      </div>
      <Notification notification={notification} isVisible={isVisible} />
      <BottomNavigation activeTab={'tasks'} />
    </div>
  )
}
