// SliderCard.jsx
import React, { useState, useEffect } from 'react';
import styles from './SliderCard.module.css';

const SliderCard = ({ booster, icon }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
  }, [icon]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className={styles.option}>
      <div className={styles.option_iconContainer}>
        <img
          src={icon}
          alt={booster.name}
          width="80%"
          onLoad={handleImageLoad}
          style={{ display: imageLoaded ? 'block' : 'none' }}
        />
      </div>
      <div className={styles.option_content}>
        <div className={styles.option_header}>
          {booster.name}{' '}
          {booster.buyed && <span className={styles.option_buyed}>Bought</span>}
        </div>
        <div className={styles.option_description}>{booster.description}</div>
      </div>
    </div>
  );
};

export default React.memo(SliderCard);