export function formatNumber(number, mode = 'default') {
  let num = Math.floor(parseFloat(number) * 100) / 100;
  num = num.toFixed(2)
  let parsedNumber = parseFloat(number)
  

  if (num.endsWith('.00')) {
    num = parseInt(number).toString()
  }

  if (mode === 'default') {
    if (parsedNumber === 0) {
      return '0'
    } else if (parsedNumber >= 1000000000) {
      return (parsedNumber / 1000000000).toFixed(2).replace(/\.00$/, '') + 'B'
    } else if (parsedNumber >= 1000000) {
      return (parsedNumber / 1000000).toFixed(2).replace(/\.00$/, '') + 'M'
    } else if (parsedNumber >= 1000) {
      return (parsedNumber / 1000).toFixed(2).replace(/\.00$/, '') + 'K'
    } else if (parsedNumber < 0.01) {
      return `~0.01`
    } else {
      return parsedNumber.toFixed(2).replace(/\.00$/, '')
    }
  }

  if (mode === 'balance') {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

  return num
}


export const shortWalletAddress = (wallet) => {
  const firstPart = wallet.slice(0, 4);
  const lastPart = wallet.slice(-4);
  return `${firstPart}...${lastPart}`;
};