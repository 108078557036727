import { useContext, useEffect } from 'react';
import { CHAIN, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { Address } from '@ton/core';
import { TonClientContext } from '#/context/ton-client-context';

export const useTonConnect = () => {
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const { tonClient } = useContext(TonClientContext);

  const walletAddress = wallet?.account?.address ? Address.parse(wallet.account.address) : undefined;
  const network = wallet?.account?.chain ?? null;

  useEffect(() => {
    const disconnectIfTestnet = async () => {
      // Если сеть тестовая, отключаем кошелек
      if (network === CHAIN.TESTNET) {
        console.warn('Testnet detected, disconnecting...');
        await tonConnectUI.disconnect();
        return; // Прекращаем выполнение дальнейшего кода
      }
    };

    disconnectIfTestnet(); 

  }, [network, tonClient, walletAddress, tonConnectUI]);

  return {
    sender: {
      send: async (args) => {
        await tonConnectUI.sendTransaction({
          messages: [
            {
              address: args.to.toString(),
              amount: args.value.toString(),
              payload: args.body?.toBoc()?.toString('base64'),
            },
          ],
          validUntil: Date.now() + 5 * 60 * 1000, 
        });
      },
      address: walletAddress,
    },

    connected: !!wallet?.account?.address,
    walletAddress: walletAddress ?? null,
    network: network,
    tonConnectUI,
    tonClient
  };
};