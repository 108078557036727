import React from 'react';
import styles from './TabsNavBar.module.css';

const TabsNavBar = ({ activeTab, setActiveTab, tabs, buttonProps = {}, titleProps = {}, ...props  }) => {
  return (
    <div className={styles.container}  {...props}>
      {tabs.map(({ name, title }, index) => {
        const isActive = activeTab === name;
        const titleStyle = { color: isActive ? '#fff' : '#fff' };

        return (
          <div
            key={index}
            className={`${styles.button} ${isActive ? styles.activeButton : ''}`}
            onClick={() => setActiveTab(name)}
            {...buttonProps}
          >
            <div className={styles.title} style={titleStyle} {...titleProps}>
              {title}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TabsNavBar;