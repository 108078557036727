import { useState } from 'react';

const useLoadImage = (delay = 500) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedEnd, setIsLoadedEnd] = useState(false);

  const handleLoadImage = () => {
    setTimeout(() => setIsLoadedEnd(true), 300);
    setTimeout(() => setIsLoaded(true), delay);
  };

  return { isLoaded, isLoadedEnd, handleLoadImage };
};

export default useLoadImage;