import React, { useState } from 'react'
import styles from './PrimaryButton.module.css'
import TelegramAPI from '#/telegram'

export default function PrimaryButton({ children, onClick, icon, style, className }) {
  const [loading, setLoading] = useState(false)

  const handleClick = async (e) => {
    TelegramAPI.Vibrate('heavy')
    if (onClick) {
      setLoading(true)
      await onClick(e)
      setLoading(false)
    }
  }

  return (
    <div className={`${styles.button} ${className}`} onClick={handleClick} style={style}>
      {icon && <div style={{ opacity: loading ? 0 : 1 }}>{icon}</div>}
      {children && <div style={{ opacity: loading ? 0 : 1 }}>{children}</div>}
      {loading && <div className={styles.spinner}></div>}
    </div>
  )
}
