import React from 'react'
import styles from './BalanceButton.module.css'
import { DepositIcon } from '../../../assets/icons'

export default function BalanceButton({ children, icon, onClick, style, iconStyle, type = 'withdraw' }) {
  return (
    <div className={`${styles.container}`} onClick={onClick} style={style}>
      <div className={styles.circle}></div>
      <div className={styles.iconContainer} >
        {/* <span className={styles.icon}>{icon}</span> */}
        <DepositIcon  className={type === 'withdraw' ? styles.withdrawIcon : styles.depositIcon} />
      </div>
      <div className={styles.title}>{children}</div>
    </div>
  )
}
