// InfoModal.jsx
import React, { useState, useCallback } from 'react';
import { ArrowSlider, BalanceCoinIcon } from '#/assets/icons';
import styles from './modal.module.css';
import Modal from '#/components/Modal/Modal';
import TelegramAPI from '#/telegram';
import { boosterIcons } from '#/data/data';
import SliderCard from './components/BoostCard/SliderCard';
import { formatNumber } from '#/services/helpers';
import { useUser } from '#/context/userContext';
import useNotification from '#/hooks/useNotification';
import Notification from '#/components/alerts/Notification';

export default function InfoModal({ mode, closeModal, boosters }) {
  const { user, buyBooster } = useUser();
  const [selectedBooster, setSelectedBooster] = useState(0);
  const { notification, isVisible, showNotification } = useNotification();

  // Универсальная функция для навигации между бустерами
  const handleNavigate = useCallback(
    (direction) => {
      TelegramAPI.Vibrate('heavy');
      setSelectedBooster((prevBooster) => {
        const newIndex = (prevBooster + direction + boosters.length) % boosters.length;
        return newIndex;
      });
    },
    [boosters.length]
  );

  // Обработка платежа
  const handlePayment = useCallback(
    (paymentMethod) => {
      TelegramAPI.Vibrate('heavy');

      if (!user) {
        showNotification('error', 'Server error', 3000);
        return;
      }

      const selectedBoosterData = boosters[selectedBooster];
      const priceCoin = selectedBoosterData.priceCoin;

      if (paymentMethod === 'coin') {
        if (!priceCoin || priceCoin <= 0) {
          showNotification('error', 'Error with booster information', 3000);
          return;
        }

        if (priceCoin > user.user.balance) {
          showNotification('error', 'Not enough balance for purchase', 3000);
          return;
        }

        buyBooster(selectedBooster)
          .then(() => {
            showNotification('success', 'Booster purchased successfully', 3000);
          })
          .catch((error) => {
            console.error('Failed to buy booster:', error);
            showNotification('error', 'Failed to purchase booster', 3000);
          });

        return;
      }

      showNotification('error', 'Undefined payment method', 3000);
    },
    [user, boosters, selectedBooster, buyBooster, showNotification]
  );

  const currentBooster = boosters[selectedBooster];
  const userBalance = user?.user.balance || 0;
  const isBoosterOwned = currentBooster.buyed;

  return (
    <Modal
      title={`Boosters (${selectedBooster + 1}/${boosters.length})`}
      onClose={closeModal}
    >
      <div className={styles.options}>
        <button
          className={styles.sliderArrowLeft}
          onClick={() => handleNavigate(-1)}
          disabled={boosters.length <= 1}
        >
          <ArrowSlider />
        </button>
        <SliderCard
          booster={currentBooster}
          mode={mode}
          icon={boosterIcons[selectedBooster].gif}
        />
        <button
          className={styles.sliderArrowRight}
          onClick={() => handleNavigate(1)}
          disabled={boosters.length <= 1}
        >
          <ArrowSlider />
        </button>
      </div>
      {!isBoosterOwned && (
        <div className={styles.modalBalance}>
          Your balance {formatNumber(userBalance)}
        </div>
      )}
      <div className={styles.paymentButtons}>
        {isBoosterOwned ? (
          <div className={styles.paymentButton_disabled}>
            You already own this booster
          </div>
        ) : (
          <div
            className={styles.paymentButton_coin}
            onClick={() => handlePayment('coin')}
            style={
              currentBooster.priceCoin > userBalance
                ? { opacity: 0.4, pointerEvents: 'none' }
                : {}
            }
          >
            <BalanceCoinIcon className={styles.coin} /> Pay{' '}
            {formatNumber(currentBooster.priceCoin)}
          </div>
        )}
      </div>
      <Notification notification={notification} isVisible={isVisible} />
    </Modal>
  );
}