// RatingPage.jsx
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import TabsNavBar from '#/components/navigation/TabsNavBar'
import styles from './RatingPage.module.css'
import BottomNavigation from '#/components/navigation/BottomNavigation/BottomNavigation'
import diamond from '#/assets/diamond.gif'
import { FiltersIcon } from '#/assets/icons'
import API from '#/api/api'
import TelegramAPI from '#/telegram'
import UserCard from './components/UserCard'
// import PersonCard from './components/PersonCard'

const tabs = [
  { name: 'day', title: 'Day' },
  { name: 'week', title: 'Week' },
]

export default function RatingPage() {
  // const [rating, setRating] = useState([
  //   {
  //     username: 'IvanAffe',
  //     name: 'Ivan',
  //     photo_id: 'AgACAgIAAxUAAWcWv3RVZBfmp41djwqiQpQvGeTIAALR2DEbCCCwS4hGmN31XWX1AQADAgADYQADNgQ',
  //     balance: '642672615.10297608',
  //     total_ghs: '67421',
  //   },
  //   {
  //     username: 'dimadze2116',
  //     name: 'Dimadze',
  //     photo_id: null,
  //     balance: '1258761.71024611',
  //     total_ghs: '72600',
  //   },
  //   {
  //     username: 'kate_lastova',
  //     name: 'Kate LA',
  //     photo_id: 'AgACAgIAAxUAAWcFpYp4HoNclXpvgzJtdBk8YcO4AAK73jEbNmY4SQzIua6flYLcAQADAgADYQADNgQ',
  //     balance: '480015.63551478',
  //     total_ghs: '67530',
  //   },
  //   {
  //     username: 'babasonay',
  //     name: 'Софья',
  //     photo_id: 'AgACAgIAAxUAAWcAAeb19USxikUAAZuqDVOMZQNorUAAAranMRvjYQYXEGy2X_0Jn9EBAAMCAANhAAM2BA',
  //     balance: '60861.90623112',
  //     total_ghs: '18150',
  //   },
  //   {
  //     username: 'Simbanimus',
  //     name: '👑 | Things',
  //     photo_id: null,
  //     balance: '58656.95436621',
  //     total_ghs: '302500',
  //   },
  //   {
  //     username: 'eatmeatme',
  //     name: 'Nick',
  //     photo_id: 'AgACAgIAAxUAAWcAAXU39FPskGjbSC5WjtMVvy_qfAAC1KcxGyNYrg6FkGRasL7b3QEAAwIAA2EAAzYE',
  //     balance: '53906.24265221',
  //     total_ghs: '24200',
  //   },
  //   {
  //     username: 'Vic_Bur',
  //     name: 'Вик',
  //     photo_id: 'AgACAgIAAxUAAWcAAZlRZ5HslpEgkfRDPeNIxuEuJAACrPIxG-rqEUkz19GOknPiHwEAAwIAA2EAAzYE',
  //     balance: '49296.31734183',
  //     total_ghs: '121000',
  //   },
  //   {
  //     username: 'Triozona',
  //     name: 'Михаил',
  //     photo_id: 'AgACAgIAAxUAAWcAAcS7YBtKSmdaYFOJk3Q14JrzygACm8UxG7ccaEivv4nLamf_egEAAwIAA2EAAzYE',
  //     balance: '34111.73240439',
  //     total_ghs: '121000',
  //   },
  //   {
  //     username: 'StingRaYXXL',
  //     name: 'Evgeniy',
  //     photo_id: null,
  //     balance: '30748.64993229',
  //     total_ghs: '66550',
  //   },
  //   {
  //     username: 'PHukolau',
  //     name: 'Николай',
  //     photo_id: null,
  //     balance: '28419.94439245',
  //     total_ghs: '70772',
  //   },
  //   {
  //     username: 'zyryanovdn',
  //     name: 'Dmitry',
  //     photo_id: null,
  //     balance: '28200.00000000',
  //     total_ghs: '0',
  //   },
  //   {
  //     username: 'IvanEvseev1',
  //     name: 'Ivan',
  //     photo_id: null,
  //     balance: '26841.45630781',
  //     total_ghs: '18150',
  //   },
  //   {
  //     username: 'danil_astapchik',
  //     name: 'Данил',
  //     photo_id: 'AgACAgIAAxUAAWcAAfTwoyoGavMWPsa5wWZtjiQlNgACr6cxG53a5CXuQq2NM_hVAAEBAAMCAANhAAM2BA',
  //     balance: '24437.29992028',
  //     total_ghs: '8150',
  //   },
  //   {
  //     username: 'blo4ik',
  //     name: 'blo4',
  //     photo_id: 'AgACAgIAAxUAAWcCmURVWkBW3nPoVCtA8Df5qA88AAKtpzEbFdj0Gqlr90bLN5-AAQADAgADYQADNgQ',
  //     balance: '23505.41684000',
  //     total_ghs: '12100',
  //   },
  //   {
  //     username: 'li4inka',
  //     name: 'li4inka',
  //     photo_id: null,
  //     balance: '22584.05486486',
  //     total_ghs: '38400',
  //   },
  //   {
  //     username: 'Nodeto',
  //     name: 'Nodeto',
  //     photo_id: null,
  //     balance: '19874.59184109',
  //     total_ghs: '121000',
  //   },
  //   {
  //     username: 'TimurBad15',
  //     name: 'Тимур',
  //     photo_id: null,
  //     balance: '19060.57448440',
  //     total_ghs: '34450',
  //   },
  //   {
  //     username: 'tonapi_crypto',
  //     name: 'Сергей',
  //     photo_id: 'AgACAgIAAxUAAWcAAejN4-VoGEBohNyMEGGybX0ZKgACy6cxG_sYpAzhtPOP7-XQAgEAAwIAA2EAAzYE',
  //     balance: '18710.94761781',
  //     total_ghs: '24200',
  //   },
  //   {
  //     username: 'zatvorius',
  //     name: 'Александр',
  //     photo_id: null,
  //     balance: '18641.22881668',
  //     total_ghs: '18150',
  //   },
  //   {
  //     username: 'Linego',
  //     name: 'Krez',
  //     photo_id: 'AgACAgIAAxUAAWcAAcoa-fiYfBsnWdUudvF07OWO9AACsqcxG80srgYzsAcf5RMgoQEAAwIAA2EAAzYE',
  //     balance: '17942.03719002',
  //     total_ghs: '6050',
  //   },
  //   {
  //     username: 'Black_ilmer',
  //     name: 'Sirius',
  //     photo_id: 'AgACAgIAAxUAAWcCmfJ8z2a8bneyU38NEidtSSWUAAJBxTEbT2cAAUtxIkjCq6vM2wEAAwIAA2EAAzYE',
  //     balance: '17566.01585850',
  //     total_ghs: '2370',
  //   },
  //   {
  //     username: 'VladMuzyka',
  //     name: 'Vladyslav',
  //     photo_id: 'AgACAgIAAxUAAWcCo79mIi8cNwcI_7Z_vhICHyMeAAK9pzEbxbftElN1veBfcpZUAQADAgADYQADNgQ',
  //     balance: '15545.24860246',
  //     total_ghs: '6780',
  //   },
  //   {
  //     username: 'Muxa84',
  //     name: 'Müxā⁸⁴',
  //     photo_id: 'AgACAgIAAxUAAWcAAYEmf9XAcksuISOt4ohcBWnJogAC0KcxGxwoMwmd6sQeAALIuAEAAwIAA2EAAzYE',
  //     balance: '14531.24651988',
  //     total_ghs: '2100',
  //   },
  //   {
  //     username: 'denozis',
  //     name: 'Denis',
  //     photo_id: null,
  //     balance: '14419.74546104',
  //     total_ghs: '36300',
  //   },
  //   {
  //     username: 'Vshato',
  //     name: 'Vshato',
  //     photo_id: 'AgACAgIAAxUAAWcAAbYVIpYp7EGo8W-jGyLmzfBVCAAC39QxG1y1wUmoUg4r5JrrmAEAAwIAA2EAAzYE',
  //     balance: '14304.52379622',
  //     total_ghs: '22',
  //   },
  //   {
  //     username: 'ogmoon',
  //     name: 'Moon',
  //     photo_id: 'AgACAgIAAxUAAWcAAcPpDWp3LrILyANXGNt7sJFPRAACPNcxGyLc-EuvoTMS0__Q3QEAAwIAA2EAAzYE',
  //     balance: '13823.63547557',
  //     total_ghs: '774',
  //   },
  //   {
  //     username: 'Galkina_Nata61',
  //     name: 'Наталья',
  //     photo_id: 'AgACAgIAAxUAAWcAAaoIqzpqRNg8BiqlJuAo-J2QFgACjbcxGyomEUpbHH1MArEvSAEAAwIAA2EAAzYE',
  //     balance: '13234.26931506',
  //     total_ghs: '16300',
  //   },
  //   {
  //     username: 'qwerty_underground',
  //     name: 'Шоколадный хомячок',
  //     photo_id: 'AgACAgIAAxUAAWcAAVhI8-bDV0KwuiQ1USviYBThsQACReAxG3kJ6Esg6f_za6YWOQEAAwIAA2EAAzYE',
  //     balance: '12863.67704897',
  //     total_ghs: '24200',
  //   },
  //   {
  //     username: 'verjgfyu',
  //     name: '512',
  //     photo_id: null,
  //     balance: '12780.70901556',
  //     total_ghs: '30250',
  //   },
  //   {
  //     username: 'MakasinRo',
  //     name: 'Макс',
  //     photo_id: 'AgACAgIAAxUAAWcAAXAU9Hdq3S0It-LQFrN0QiJyUAACsacxGxFs7RvLlyIChDUrYwEAAwIAA2EAAzYE',
  //     balance: '12698.28368206',
  //     total_ghs: '112270',
  //   },
  //   {
  //     username: 'dragonbronz',
  //     name: 'Артём',
  //     photo_id: 'AgACAgIAAxUAAWcAAVkK2epeDvZs8BOmmkp5X87G3gACracxG06HdxcOhN0doFCAkAEAAwIAA2EAAzYE',
  //     balance: '12598.34389890',
  //     total_ghs: '12100',
  //   },
  //   {
  //     username: 'h4sta_1a_vist4',
  //     name: 'Mickilito',
  //     photo_id: null,
  //     balance: '12341.51167108',
  //     total_ghs: '16300',
  //   },
  //   {
  //     username: 'K0ba1n',
  //     name: 'Котяра Котовский',
  //     photo_id: 'AgACAgIAAxUAAWcBcyEDILsNIhkF0BGvCdaa2YDGAAKzpzEbvzHACBjwts850ghnAQADAgADYQADNgQ',
  //     balance: '10704.36846113',
  //     total_ghs: '18150',
  //   },
  //   {
  //     username: null,
  //     name: 'ROG',
  //     photo_id: null,
  //     balance: '10686.87872956',
  //     total_ghs: '6050',
  //   },
  //   {
  //     username: 'djmic2k',
  //     name: 'Mic',
  //     photo_id: null,
  //     balance: '10400.37445760',
  //     total_ghs: '193600',
  //   },
  //   {
  //     username: 'Olezhanio_Nagibe',
  //     name: 'Олег',
  //     photo_id: 'AgACAgIAAxUAAWcAAYVZD1X5Acf0EVgLz_7-ewiGYAAC_OQxG7GLgEvKs1FH1eyY_QEAAwIAA2EAAzYE',
  //     balance: '10043.50324719',
  //     total_ghs: '40500',
  //   },
  //   {
  //     username: 'igortkachev001',
  //     name: 'Игорь',
  //     photo_id: null,
  //     balance: '10026.13860213',
  //     total_ghs: '2465',
  //   },
  //   {
  //     username: 'designtector',
  //     name: 'Nikita',
  //     photo_id: 'AgACAgIAAxUAAWcAAYiKPLRlPkUB56Vu6WdrzDIVawACqacxGzuMThkk6-wBYi0OAwEAAwIAA2EAAzYE',
  //     balance: '9610.77338123',
  //     total_ghs: '48400',
  //   },
  //   {
  //     username: 'BobElsymbl',
  //     name: 'Zeroberto',
  //     photo_id: 'AgACAgIAAxUAAWcAAXH5R_u_AQAB_mbgy1NM0gQELtEAAsqnMRudhxcb5YW78hDN3LgBAAMCAANhAAM2BA',
  //     balance: '9209.48016001',
  //     total_ghs: '18150',
  //   },
  //   {
  //     username: 'SanchesIrk',
  //     name: 'Александр',
  //     photo_id: 'AgACAgIAAxUAAWcAAXV8NjkK7UqygvEl_rssaZTsxQACr6cxG-w40BG9c3QTy8pqUwEAAwIAA2EAAzYE',
  //     balance: '9013.31005892',
  //     total_ghs: '24200',
  //   },
  //   {
  //     username: 'mikhail_mobile',
  //     name: 'Mikhail',
  //     photo_id: 'AgACAgIAAxUAAWcAAZEMS8tIw8_Ezfd4ETg9eRk_vgAC_6cxG_nRVgnNUb25L8k_CQEAAwIAA2EAAzYE',
  //     balance: '9004.71937155',
  //     total_ghs: '30250',
  //   },
  //   {
  //     username: 'aee78',
  //     name: 'Aндрей',
  //     photo_id: 'AgACAgIAAxUAAWcAAW6rMKXtBa_0NBBNwCkXnro37AACsacxG786Yx85szqsd8geKAEAAwIAA2EAAzYE',
  //     balance: '8817.07923868',
  //     total_ghs: '32695',
  //   },
  //   {
  //     username: 'Elizbar84',
  //     name: 'Эдуард Павлов',
  //     photo_id: 'AgACAgIAAxUAAWcCqZ4Jj0ZBPaL9HO1zpj6hdnjoAAKppzEbPCL_HZPdVtUq-EZRAQADAgADYQADNgQ',
  //     balance: '8439.48282313',
  //     total_ghs: '2122',
  //   },
  //   {
  //     username: 'olegin77',
  //     name: 'OS',
  //     photo_id: null,
  //     balance: '7810.91397916',
  //     total_ghs: '4200',
  //   },
  //   {
  //     username: 'Boris_77777',
  //     name: 'Борис',
  //     photo_id: 'AgACAgIAAxUAAWcAAVduRBgSMRMguwEvhrFuLgu_vgAC4OoxG2Td0ElwwA-odEApqwEAAwIAA2EAAzYE',
  //     balance: '7800.00000000',
  //     total_ghs: '16300',
  //   },
  //   {
  //     username: 'ctrl_p',
  //     name: 'М',
  //     photo_id: null,
  //     balance: '7637.29840098',
  //     total_ghs: '16570',
  //   },
  //   {
  //     username: 'alexx_br',
  //     name: '. alex',
  //     photo_id: null,
  //     balance: '7506.57899331',
  //     total_ghs: '18150',
  //   },
  //   {
  //     username: 'UnWordly_88',
  //     name: 'Андрей ☀️',
  //     photo_id: 'AgACAgIAAxUAAWcAAWBc4UuCYrK_O_7ALHv0GFScIQACxqcxG7gWxhMX8d9WP9FVbwEAAwIAA2EAAzYE',
  //     balance: '7378.75165111',
  //     total_ghs: '24200',
  //   },
  //   {
  //     username: 'mjmtts',
  //     name: 'АС',
  //     photo_id: 'AgACAgIAAxUAAWcAAVfofV7pV5Sz_ob83cDYvvzFbQACs6cxG1t0riCYWLT4lWqWxgEAAwIAA2EAAzYE',
  //     balance: '7188.77241840',
  //     total_ghs: '26300',
  //   },
  //   {
  //     username: 'MaxDevDes',
  //     name: 'Maksim | Develop & Design',
  //     photo_id: 'AgACAgIAAxUAAWcZAt_aWfdJIfunR2b0jU5M0UMGAAKj0jEbRMUJSrlqutDLc-ldAQADAgADYQADNgQ',
  //     balance: '7000.03547534',
  //     total_ghs: '22',
  //   },
  //   {
  //     username: 'anselele',
  //     name: 'anselele',
  //     photo_id: 'AgACAgIAAxUAAWcAAcliSYO_2Q9Y3xq9Nh3FhmI4oAACuacxG_D_AhzQCb3Z6BDViQEAAwIAA2EAAzYE',
  //     balance: '6897.49246219',
  //     total_ghs: '12100',
  //   },
  //   {
  //     username: 'llFahrenheitll',
  //     name: 'Fahrenheit',
  //     photo_id: 'AgACAgIAAxUAAWcAAY2bQrbXtAKgcvua33GMiAYkbwAC5KcxG0QapRy9ixmUgOy6KAEAAwIAA2EAAzYE',
  //     balance: '6657.77974445',
  //     total_ghs: '6050',
  //   },
  //   {
  //     username: 'Brok1l',
  //     name: 'Bro',
  //     photo_id: 'AgACAgIAAxUAAWcAAVBSn_3q0PP_PrwLEn4lCNHsTwACHtsxGzE5YEi7jVn1jkborgEAAwIAA2EAAzYE',
  //     balance: '6528.84669281',
  //     total_ghs: '14200',
  //   },
  //   {
  //     username: 'vasya_gun',
  //     name: 'Taras',
  //     photo_id: 'AgACAgIAAxUAAWcAAeDzClBJaTrURdCi7_9fZ4gUBQACOKgxG0OqkAt-GabZfyvxLgEAAwIAA2EAAzYE',
  //     balance: '6457.84355004',
  //     total_ghs: '6050',
  //   },
  //   {
  //     username: 'sergeybrava',
  //     name: 'Sergey',
  //     photo_id: 'AgACAgIAAxUAAWcAAcCE15VTkRryFa94_n5-tjLjMgACuKcxGx0kyQ84YaikgC1DoAEAAwIAA2EAAzYE',
  //     balance: '6170.93987435',
  //     total_ghs: '22',
  //   },
  //   {
  //     username: 'pereiro14',
  //     name: 'Sebastian',
  //     photo_id: 'AgACAgIAAxUAAWcAAWONDrcb5Bn12r5AelorNqWDvwACy6cxGwNxpwLAafh_i7ZgJQEAAwIAA2EAAzYE',
  //     balance: '5757.27055427',
  //     total_ghs: '20367',
  //   },
  //   {
  //     username: 'kianoru',
  //     name: 'Kiano',
  //     photo_id: null,
  //     balance: '5576.43630900',
  //     total_ghs: '730',
  //   },
  //   {
  //     username: 'chota_consentida',
  //     name: 'Аnna',
  //     photo_id: null,
  //     balance: '5546.12903721',
  //     total_ghs: '12465',
  //   },
  //   {
  //     username: 'Vadim2bad',
  //     name: 'Вадим',
  //     photo_id: null,
  //     balance: '5035.33549667',
  //     total_ghs: '18150',
  //   },
  //   {
  //     username: 'Kak_TaK77777777',
  //     name: 'Kak_TaK?',
  //     photo_id: 'AgACAgIAAxUAAWcAAYH5eE5MQ4rjmS2ubzRPZaNCzQACDN0xGypwMEhgQ5LN1gvrYgEAAwIAA2EAAzYE',
  //     balance: '4730.12528888',
  //     total_ghs: '127050',
  //   },
  //   {
  //     username: 'Titrom',
  //     name: 'Roman',
  //     photo_id: 'AgACAgIAAxUAAWcAAVhL4SI2tpJI9hfwJ4m_gbSW3QACtKcxG1o73wzEIwYuzRZKtAEAAwIAA2EAAzYE',
  //     balance: '4696.02502694',
  //     total_ghs: '14200',
  //   },
  //   {
  //     username: 'Kedr_08',
  //     name: 'Андрей',
  //     photo_id: 'AgACAgIAAxUAAWcBID9Gu0BYEAn3Qfhwgs0S0yFAAAKspzEbrMqqPYqTSOu99SJEAQADAgADYQADNgQ',
  //     balance: '4672.55892442',
  //     total_ghs: '2100',
  //   },
  //   {
  //     username: 'xxKaKTaKxx',
  //     name: 'Егор',
  //     photo_id: null,
  //     balance: '4614.42982395',
  //     total_ghs: '18150',
  //   },
  //   {
  //     username: 'barboskich',
  //     name: 'Кирилл 🐶',
  //     photo_id: 'AgACAgIAAxUAAWcAAVnJt803LBtPVJ2ZWrHjOHN-LgACCKgxG7gGtxWnkm1tZ-VPNAEAAwIAA2EAAzYE',
  //     balance: '4364.13424885',
  //     total_ghs: '60500',
  //   },
  //   {
  //     username: 'murzilc',
  //     name: 'Александр',
  //     photo_id: 'AgACAgIAAxUAAWcCntIB3rDU3_rUpnGXV8sy2ZIEAAKspzEb6U5EHpWVchptcFR9AQADAgADYQADNgQ',
  //     balance: '4300.00000000',
  //     total_ghs: '6050',
  //   },
  //   {
  //     username: 'nuclearatom',
  //     name: 'John',
  //     photo_id: 'AgACAgIAAxUAAWcAAfb87R5rcLMPTnHhylXW-trHzgACqacxG7U3fRjJd4KBDT8J5wEAAwIAA2EAAzYE',
  //     balance: '4280.51271032',
  //     total_ghs: '8420',
  //   },
  //   {
  //     username: 'vasyaabibas',
  //     name: 'Kirill Andreich',
  //     photo_id: null,
  //     balance: '4147.65469972',
  //     total_ghs: '2100',
  //   },
  //   {
  //     username: 'sourcreamy',
  //     name: 'Сметанный Патриот',
  //     photo_id: 'AgACAgIAAxUAAWcAAXrGp_gyNhdYuo7lMgor2lMEBwACkcYxG1NzIEko2Tepbud3uwEAAwIAA2EAAzYE',
  //     balance: '4078.49195446',
  //     total_ghs: '103100',
  //   },
  //   {
  //     username: 'LaraD67',
  //     name: 'Лариса',
  //     photo_id: null,
  //     balance: '4063.94099520',
  //     total_ghs: '270',
  //   },
  //   {
  //     username: 'linveyn',
  //     name: 'linvey',
  //     photo_id: 'AgACAgIAAxUAAWcAAVfhnlPS1cf_XN2i_4qDddGO3QACCtsxGyByoElVE81pc0IjwgEAAwIAA2EAAzYE',
  //     balance: '4025.78016819',
  //     total_ghs: '34450',
  //   },
  //   {
  //     username: 'PolyakRussia',
  //     name: 'PolyakRussia',
  //     photo_id: 'AgACAgIAAxUAAWcAAcEexPv_s37gxbGu0iXqGjrl0QACq6cxG4UIKCVZkH31N6_nNgEAAwIAA2EAAzYE',
  //     balance: '4019.66660723',
  //     total_ghs: '8150',
  //   },
  //   {
  //     username: 'labuba',
  //     name: 'Nik L.',
  //     photo_id: null,
  //     balance: '3973.65374133',
  //     total_ghs: '12644',
  //   },
  //   {
  //     username: 'Dem0N98ru',
  //     name: 'Alexander',
  //     photo_id: 'AgACAgIAAxUAAWcAAVcahXvVK40HNNpnAoY36fZtHwAC5qcxGxRdIA9jbXKjT8p1MwEAAwIAA2EAAzYE',
  //     balance: '3961.50540883',
  //     total_ghs: '26300',
  //   },
  //   {
  //     username: 'startac79',
  //     name: '@toystory',
  //     photo_id: 'AgACAgIAAxUAAWcBMd-O-yVHupmmcc3yqP_3CTzyAAK0pzEbXqLjA-F0DJGRdQ2kAQADAgADYQADNgQ',
  //     balance: '3955.07253130',
  //     total_ghs: '13100',
  //   },
  //   {
  //     username: 'user224411',
  //     name: 'Andrey',
  //     photo_id: 'AgACAgIAAxUAAWcAAXnhehy9eFBa0aakt3jD7mq6XgACIcAxGxtJaUhSkMyaGRG8gwEAAwIAA2EAAzYE',
  //     balance: '3947.98245866',
  //     total_ghs: '774',
  //   },
  //   {
  //     username: 'Nine_Candles',
  //     name: '9Candles Admin',
  //     photo_id: 'AgACAgIAAxUAAWcAAXm5XLDyeLKA2bP7F7rf2xBHIQAChekxGwix-Eiys5tZJvLZtgEAAwIAA2EAAzYE',
  //     balance: '3895.98939890',
  //     total_ghs: '12100',
  //   },
  //   {
  //     username: 'IrinaZhest',
  //     name: 'Irina',
  //     photo_id: null,
  //     balance: '3831.20981823',
  //     total_ghs: '22',
  //   },
  //   {
  //     username: 'gavrilov013',
  //     name: 'Artem',
  //     photo_id: null,
  //     balance: '3821.75228300',
  //     total_ghs: '730',
  //   },
  //   {
  //     username: 'Komiks1',
  //     name: 'Maxim',
  //     photo_id: 'AgACAgIAAxUAAWcAAWxUnhdFFUaTyTfxHFX46L2ZXQACracxG3TCQhYRrxwVukzXzQEAAwIAA2EAAzYE',
  //     balance: '3797.09033210',
  //     total_ghs: '12372',
  //   },
  //   {
  //     username: 'SeriousJazz',
  //     name: 'Виталий',
  //     photo_id: 'AgACAgIAAxUAAWcAAVgwloei2-sg6lQzuQXIh_ElCAACtqcxG0_fGR-Ug8FCAAEYTCABAAMCAANhAAM2BA',
  //     balance: '3655.98229221',
  //     total_ghs: '8238',
  //   },
  //   {
  //     username: 'perfectangel',
  //     name: 'Sergio',
  //     photo_id: null,
  //     balance: '3637.88027728',
  //     total_ghs: '260150',
  //   },
  //   {
  //     username: 'gr65o',
  //     name: 'Og🦴',
  //     photo_id: null,
  //     balance: '3597.09620777',
  //     total_ghs: '2100',
  //   },
  //   {
  //     username: 'stroy_zavod',
  //     name: 'Димас',
  //     photo_id: 'AgACAgIAAxUAAWcAAVvYyui3g_B10DSCEdXoxJi35AACrKcxG4AnciH-BiAkNfx5KQEAAwIAA2EAAzYE',
  //     balance: '3530.23945275',
  //     total_ghs: '4200',
  //   },
  //   {
  //     username: 'PetrOsetrr',
  //     name: 'Petr',
  //     photo_id: 'AgACAgIAAxUAAWcCnJCPWecd-HqFuPViLdt7qZYXAAKypzEb2A2UBTjDaIfiRMY9AQADAgADYQADNgQ',
  //     balance: '3465.86217827',
  //     total_ghs: '3370',
  //   },
  //   {
  //     username: 'goooodw1n',
  //     name: 'Гудвин',
  //     photo_id: 'AgACAgIAAxUAAWcBRjoQLtIDMNFFY0CK23jyLSvaAAJ12jEbYAb4SS_ol_-FdUEaAQADAgADYQADNgQ',
  //     balance: '3395.09776245',
  //     total_ghs: '34350',
  //   },
  //   {
  //     username: 'usmfox',
  //     name: 'Ildar',
  //     photo_id: 'AgACAgIAAxUAAWcBnFVdD7nF5yDpukM1RPfB1Z8uAAKppzEbNAV7GekNnBYfAtpwAQADAgADYQADNgQ',
  //     balance: '3319.96553174',
  //     total_ghs: '0',
  //   },
  //   {
  //     username: 'DrEvg89',
  //     name: 'Evgeniy',
  //     photo_id: null,
  //     balance: '3227.37325996',
  //     total_ghs: '12100',
  //   },
  //   {
  //     username: 'telqe',
  //     name: 'R',
  //     photo_id: 'AgACAgIAAxUAAWcAAZFWoezXFmQiw6Dq7dc-xo7o4AACz6cxG82WoR2mY9BG3pFNgwEAAwIAA2EAAzYE',
  //     balance: '3220.08978835',
  //     total_ghs: '8216',
  //   },
  //   {
  //     username: 'ivankajaikin',
  //     name: 'Ivan 🥠',
  //     photo_id: 'AgACAgIAAxUAAWcAAaxpD1uh3OKN4w_AqpPvILwilwACqacxG-dZBw5xhwO5o1kE1QEAAwIAA2EAAzYE',
  //     balance: '3112.80697664',
  //     total_ghs: '32953',
  //   },
  //   {
  //     username: 'efomin_sml',
  //     name: 'Evgeny 🪂',
  //     photo_id: 'AgACAgIAAxUAAWcAAfUbKs27Eum7EPgTlHBE_-jMhAACr6cxG_p8WA1foHu8ayoLPwEAAwIAA2EAAzYE',
  //     balance: '3103.20615499',
  //     total_ghs: '2100',
  //   },
  //   {
  //     username: 'bigdaylotstodo',
  //     name: 'S',
  //     photo_id: 'AgACAgQAAxUAAWcAAVgxCmIHraKl86aWSAoKJBHCoQACM7oxGyXSQVJfCkwRkJpR_gEAAwIAA2EAAzYE',
  //     balance: '3054.86123718',
  //     total_ghs: '48664',
  //   },
  //   {
  //     username: 'Den290878',
  //     name: 'Denis',
  //     photo_id: null,
  //     balance: '3000.00000000',
  //     total_ghs: '0',
  //   },
  //   {
  //     username: 'Hqd32r',
  //     name: '🐤 + Hqd32r + Gra-Gra',
  //     photo_id: 'AgACAgIAAxUAAWcCSzS0_LReen8Ixz5PzVCtw-9ZAAIuuzEbEpW5SN4qd3rGQW4xAQADAgADYQADNgQ',
  //     balance: '2991.56522770',
  //     total_ghs: '8150',
  //   },
  //   {
  //     username: 'Rudiarius',
  //     name: 'Alex',
  //     photo_id: null,
  //     balance: '2978.17098041',
  //     total_ghs: '2850',
  //   },
  //   {
  //     username: 'sos1_suka',
  //     name: 'SOSISKA | FOMO',
  //     photo_id: 'AgACAgIAAxUAAWcAAZzvSSg4lRJ0yU7OXlXqCtT1cAACuacxG2yalTZRWP-zdZ0LSAEAAwIAA2EAAzYE',
  //     balance: '2971.90250878',
  //     total_ghs: '270',
  //   },
  //   {
  //     username: 'bash_ilya',
  //     name: 'Ilya',
  //     photo_id: 'AgACAgIAAxUAAWcAAVd_Rml50Yl7m2s8CZsI_ITTDQACqqcxG4iHgCD2217JHm6ysQEAAwIAA2EAAzYE',
  //     balance: '2948.70622445',
  //     total_ghs: '6050',
  //   },
  //   {
  //     username: 'tordepth6g273992vegs5wywjejs8d7',
  //     name: '🩸',
  //     photo_id: 'AgACAgQAAxUAAWcAAW9Drjirio3M64VZ6iGGNU9O6QACisIxG_H2UVMZrpG3Wm4YXAEAAwIAA2EAAzYE',
  //     balance: '2929.88577234',
  //     total_ghs: '6590',
  //   },
  //   {
  //     username: 'Rayek74',
  //     name: 'Эдуард',
  //     photo_id: null,
  //     balance: '2912.24594555',
  //     total_ghs: '18150',
  //   },
  //   {
  //     username: 'aartur_n',
  //     name: 'Artur',
  //     photo_id: null,
  //     balance: '2900.56389667',
  //     total_ghs: '6050',
  //   },
  //   {
  //     username: 'exceptedtocyclone',
  //     name: 'Yo,',
  //     photo_id: 'AgACAgIAAxUAAWcBRXwu-WKWiitB77f51Z8tz_3XAAIgqDEbkP9RPhb7Py5qKIlvAQADAgADYQADNgQ',
  //     balance: '2851.49698839',
  //     total_ghs: '6050',
  //   },
  // ])
  const [rating, setRating] = useState([])
  const [userPosition, setUserPosition] = useState()
  const [userStats, setUserStats] = useState()
  const [filter, setFilter] = useState('balance')
  const [activeTab, setActiveTab] = useState('day')
  const [loading, setLoading] = useState(false)

  const fetchTop = useCallback(async (currentFilter, ratingType) => {
    setLoading(true)
    try {
      const users = await API.user.getUserTop(currentFilter, ratingType)
      setRating(users.topUsers)
      setUserPosition(users.userPosition)
      setUserStats(users.userStats)
    } catch (error) {
      console.error('Failed to fetch top users:', error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    setRating([])
    setUserPosition(null)
    setUserStats(null)
    const ratingType = activeTab === 'day' ? 'daily' : 'weekly'
    fetchTop(filter, ratingType)
  }, [filter, activeTab, fetchTop])

  const handleClickFilter = useCallback(() => {
    TelegramAPI.Vibrate('heavy')
    setFilter((prevFilter) => (prevFilter === 'balance' ? 'ghs' : 'balance'))
  }, [])

  const renderedUsers = useMemo(() => {
    return rating.map((user, index) => <UserCard key={user.id || index} item={user} index={index} filter={filter} />)
  }, [rating, filter])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={diamond} className={styles.gif} alt="Diamond" loading="lazy" />
        <div className={styles.pageHeader}>Top miners</div>
      </div>

      <TabsNavBar activeTab={activeTab} tabs={tabs} setActiveTab={setActiveTab} />

      <div className={styles.filtersButton} onClick={handleClickFilter}>
        <FiltersIcon additionalColor="#0097EC" /> {filter === 'balance' ? 'Balance' : 'TH/s'}
      </div>

      <div className={styles.usersTable}>
        {renderedUsers}
        {!rating?.length && <div className={styles.loader}></div>}
        {/* <PersonCard  item={user} filter={filter} index={userPosition}/>
        <UserCard  item={user} index={index} filter={filter} /> */}

        {userStats && userPosition && userPosition > 100 && (
          <div className={styles.personCard}>
            <UserCard item={userStats} index={userPosition} filter={filter} type={'personal'} />
          </div>
        )}
      </div>

      <BottomNavigation activeTab="rating" />
    </div>
  )
}
