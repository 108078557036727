import React, { useState, forwardRef, useImperativeHandle } from 'react'
import styles from './Modal.module.css'
import TelegramAPI from '../../telegram'

const Modal = forwardRef(
  (
    {
      children,
      onClose,
      modalHeight,
      title,
      fullScreen = false,
      background,
      boxShadow = ' 0 -4px 6px 0 rgba(0, 0, 0, 0.25)',
    },
    ref
  ) => {
    const [closing, setClosing] = useState(false)

    const closeHandler = () => {
      TelegramAPI.OffBackButtonClick()
      TelegramAPI.HideBackButton()
      setClosing(true)
      TelegramAPI.Vibrate('soft')
      setTimeout(() => {
        onClose()
      }, 500)
    }

    useImperativeHandle(ref, () => ({
      close: closeHandler,
    }))

    const handleOutsideClick = (event) => {
      if (event.target.classList.contains(styles.modalOverlay)) {
        closeHandler()
      }
    }

    return (
      <div className={`${styles.modalOverlay} ${closing ? styles.closing : ''}`} onClick={handleOutsideClick}>
        <div
          className={`${styles.modalContent} ${closing ? styles.slideDown : styles.slideUp}`}
          style={
            fullScreen
              ? { height: '100%' }
              : {
                  height: modalHeight,
                  backgroundImage: background ? `url(${background})` : 'rgba(11, 11, 11, 0.95)',
                  backgroundSize: 'cover',
                  boxShadow: boxShadow,
                }
          }>
          {!fullScreen && (
            <div className={styles.modalHeader}>
              {title && <div className={styles.modalTitle}>{title}</div>}
              <button className={styles.modalCloseButton} onClick={closeHandler}>
                &times;
              </button>
            </div>
          )}
          {children}
        </div>
      </div>
    )
  }
)

export default Modal
