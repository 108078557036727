import React, { useState } from 'react';
import styles from './TaskCard.module.css';
import {
  BrowserIcon,
  CardIcon,
  CoinIcon,
  GroupIcon,
  InstagramIcon,
  TelegramIcon,
  TwitterIcon,
} from '../../../../assets/icons';
import { formatNumber } from '../../../../services/helpers';

const TaskCard = ({ task, onClick }) => {
  const [loading, setLoading] = useState(false); 

  const handleClick = async () => {
    setLoading(true); 
    await onClick();  
    setLoading(false); 
  };

  const buttonText = (status) => {
    if (loading) {
      return <div className={styles.spinner}></div>; 
    }
    if (status === 'viewed') {
      return 'check';
    } else if (status === 'completed') {
      return (
        <svg width="18" height="18" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" opacity={0.6}>
          <rect x="1" y="1" width="20" height="20" rx="5" stroke="white" strokeWidth="1.5" />
          <path
            d="M5 10.875L9.12499 15L17 7.125"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    } else {
      return 'start';
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.iconContainer}>
        {task.type === 'telegram' && <TelegramIcon width={28} height={28} />}
        {task.type === 'instagram' && <InstagramIcon width={28} height={28} />}
        {task.type === 'twitter' && <TwitterIcon width={28} height={28} />}
        {task.type === 'friends' && <GroupIcon width={28} height={28} />}
        {task.type === 'link' && <BrowserIcon width={28} height={28} />}
        {task.type === 'shop' && <CardIcon width={28} height={28} />}
      </div>
      <div className={styles.rightSide}>
        <div className={styles.info}>
          <div className={styles.info__name}>{task.title}</div>
          <div className={styles.info__reward}>
            <CoinIcon width={14} height={14} /> +{formatNumber(task.reward)}
          </div>
        </div>
        <div
          onClick={handleClick} 
          className={styles.checkMark}
          style={task.completing_status === 'completed' ? { background: 'transparent', cursor: 'unset' } : {}}
        >
          {buttonText(task.completing_status)}
        </div>
      </div>
    </div>
  );
};

export default TaskCard;