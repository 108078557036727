import React from 'react'
import styles from './BoosterCard.module.css'

import { boosterIcons } from '#/data/data'
import TelegramAPI from '#/telegram'

const BoosterCard = ({ item, selected, onSelect }) => {
  const getButtonTitle = () => {
    if (item.buyed) return 'Bought';
    return selected ? 'Selected' : 'Add to order';
  };

  const onClick = () => {
    if (!item.buyed) {
      TelegramAPI.Vibrate('heavy');
      onSelect(item.id);
    }
  };
  
  return (
    <div className={styles.boster}>
      <div className={styles.boosterIconContainer}>
        <img src={boosterIcons[item.id].gif} alt='' className={styles.boosterIcon} />
      </div>
      <div>
        <div className={styles.boosterHeader}>
          <div className={styles.boosterTitle}>{item.name}</div>
          <button className={`${styles.boosterButton} ${!selected && styles.active} ${item.buyed && styles.buyed}`} onClick={onClick}>
            {getButtonTitle()}
          </button>
        </div>
        <div className={styles.boosterDescription}>{item.description}</div>
      </div>
    </div>
  )
}

export default BoosterCard

