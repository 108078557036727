import React, { useState, useCallback } from 'react';
import styles from './FriendCard.module.css';
import { CoinIcon } from '#/assets/icons';
import { formatNumber } from '#/services/helpers';
import { SERVER_LINK } from '#/data/serverLink';

const FriendCard = React.memo(({ user }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = useCallback(() => {
    setLoading(false);
  }, []);


  const avatarStyle = user?.photo_id
    ? {
        backgroundImage: `url(${SERVER_LINK}/user-photo/${user.photo_id})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }
    : {};

  return (
    <div className={styles.container}>
      <div className={styles.avatarContainer}>
        {user?.photo_id ? (
          <>
            {loading && (
              <div className={styles.spinnerContainer}>
                <div className={styles.spinner}></div>
              </div>
            )}
            <img
              src={`${SERVER_LINK}/user-photo/${user.photo_id}`}
              alt={user.name}
              className={styles.avatar}
              onLoad={handleImageLoad}
              loading="lazy"
            />
          </>
        ) : (
          <div className={styles.defaultAvatar}>{user?.name.slice(0, 1)}</div>
        )}
      </div>

      <div className={styles.userContainer}>
        <div className={styles.header}>
          <div className={styles.header__username}>{user.name}</div>
        </div>
        <div className={styles.reward}>
          <CoinIcon height={12} /> +{formatNumber(user.reward)} <span>& + {formatNumber(user.refRewards)} from their friends</span>
        </div>
      </div>
    </div>
  );
});

export default FriendCard;